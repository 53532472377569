import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import video from "../images/bg.mp4"
import services from "../data/services"
import { Modal } from "react-bootstrap"

const IndexPage = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const data = useStaticQuery(graphql`
    query {
      videoImg: file(relativePath: { eq: "lakeland-video-amazon.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImg: file(relativePath: { eq: "bg-video-mobile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Aviation Consultants" />

      <section id="slider" className="hero p-0 odd mt-5 mt-lg-0">
        <div className="swiper-container no-slider animation slider-h-100 slider-h-auto">
          <div className="swiper-wrapper">
            <div className="swiper-slide slide-center">
              <video
                className="full-image to-bottom d-none d-md-block"
                data-mask="50"
                src={video}
                autoPlay
                muted
                loop
              ></video>
              <div className="d-block d-md-none full-image">
                <Img
                  className=" full-image"
                  fluid={data.bgImg.childImageSharp.fluid}
                />
              </div>
              <div className="slide-content row">
                <div className="col-12 d-flex justify-content-start justify-content-md-end inner">
                  <div className="right text-left">
                    <h1
                      data-aos="zoom-in"
                      data-aos-delay="2000"
                      className="title effect-static-text"
                    >
                      <span className="featured bottom">
                        <span>Aviation</span>
                      </span>
                      <br />
                      Consulting
                    </h1>
                    <p
                      data-aos="zoom-in"
                      data-aos-delay="2400"
                      className="description bigger"
                    >
                      We've built a reputation for high quality, insightful, and
                      objective work.
                    </p>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="2800"
                      className="buttons"
                    >
                      <div className="d-sm-inline-flex">
                        <Link
                          to="/contact"
                          className="smooth-anchor mt-4 btn primary-button"
                        >
                          GET IN TOUCH
                        </Link>
                        <Link
                          to="#services"
                          className="smooth-anchor ml-sm-4 mt-4 btn outline-button"
                        >
                          LEARN MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="lakeland" className="section-4">
        <div className="container smaller">
          <div className="row text-center intro">
            <div className="col-12">
              <span className="pre-title">
                Lakeland Linder International Testimonial
              </span>
              <h2>
                Planning with{" "}
                <span className="featured">
                  <span>Results</span>
                </span>
              </h2>
              <p className="text-max-800">
                Learn how the planning services of R.A. Wiedemann &amp;
                Associates lead to Amazon Air building an air cargo facility and
                adding $3 million per year to Airport revenues.
              </p>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-12 gallery">
              <a
                onClick={handleShow}
                href="#!"
                data-toggle="modal"
                data-target="#videoModal"
                className="square-image d-flex justify-content-center align-items-center"
              >
                <i className="icon bigger fas fa-play clone"></i>
                <i className="icon bigger fas fa-play"></i>
                <Img
                  className="fit-image"
                  fluid={data.videoImg.childImageSharp.fluid}
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="section-1 offers">
        <div className="container">
          <div className="row intro">
            <div className="col-12 col-md-9 align-self-center text-center text-md-left">
              <span className="pre-title m-auto ml-md-0">
                Our Areas of Focus
              </span>
              <h2>
                Aviation{" "}
                <span className="featured">
                  <span>Services</span>
                </span>
              </h2>
              <p>
                We employ national and international experts who specialize in a
                variety of aviation specific disciplines. This specialized
                expertise can be brought to bear on projects ranging from small
                general aviation airports to national aviation system plans.
              </p>
            </div>
            <div className="col-12 col-md-3 align-self-end"></div>
          </div>
          <div className="row justify-content-center items">
            {services.map((service, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4 item">
                <Link to={service.link}>
                  <div className="card">
                    <i className={service.icon}></i>
                    <h4>{service.name}</h4>
                    <p className="text-dark">{service.description}</p>
                    <i className="btn-icon pulse fas fas fa-arrow-right"></i>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-80w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title="videoModal"
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/69FMajPqz0g?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </Layout>
  )
}

export default IndexPage
